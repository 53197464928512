<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss">
#app * {
  font-family: 'Rubik', sans-serif;
}
#app .font-krona {
  font-family: 'Krona One', sans-serif;
}
* {
  scrollbar-color: #da1617 #2c2c2c;
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 2px;
  background-color: var(--v-dark_gray-base);
}
*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--v-error-base);
}
*::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: var(--v-dark_gray-base);
}
body,
#app {
  background: var(--v-black-base);
}
a {
  text-decoration: none;
}
.f40 {
  font-size: 40px;
}
.f28 {
  font-size: 28px;
  font-weight: 500;
}
.f18 {
  font-size: 18px;
  font-weight: 700;
}
.wrap {
  max-width: 1440px;
  width: 100%;
  padding: 0 100px;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
.height100 {
  height: 100%;
}
.link {
  cursor: pointer;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
}
.v-btn__content {
  text-transform: none;
  opacity: 1 !important;
  font-weight: 700;
}
.clip2 {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 54px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 1152px) {
  .wrap {
    padding: 0 60px;
  }
  .f40 {
    font-size: 36px;
  }
  .f28 {
    font-size: 26px;
  }
}
@media screen and (max-width: 1133px) {
  .f40 {
    font-size: 40px;
  }
  .f28 {
    font-size: 28px;
  }
}
@media screen and (max-width: 500px) {
  .f40 {
    font-size: 28px;
  }
}
</style>
