import Vue from 'vue';
import Vuex from 'vuex';
import '@/plugins/axios';
import router from '@/router/index';

Vue.use(Vuex);

const api = '/api/v1/movies/';

export default new Vuex.Store({
  state: {
    moviesList: { results: [] },
    movieItem: {},
  },
  actions: {
    getMoviesList(context, { page, search, genre }) {
      if (genre != 'all' || search) {
        if (
          `${router.app._route.path}${genre != 'all' ? `?genre=${genre}&` : '?'}${search ? `src=${search}&` : ''}page=${page}` !=
          router.app._route.fullPath
        ) {
          window.history.pushState(
            {},
            '',
            `${router.app._route.path}${genre != 'all' ? `?genre=${genre}&` : '?'}${search ? `src=${search}&` : ''}page=${page}`
          );
        }
        return axios
          .get(`${api}${genre != 'all' ? `?genre=${genre}&` : '?'}${search ? `src=${search}&` : ''}page=${page - 1 || 0}`)
          .then((res) => {
            context.commit('GET_MOVIESLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          window.history.pushState({}, '', `${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_MOVIESLIST', res.data);
        });
      }
    },
    setMoviesList: (context, payload) => {
      context.commit('GET_MOVIESLIST', payload);
    },
    getMoviesItem(context) {
      return axios.get(`${api}${router.app._route.params.movieId}`).then((res) => {
        context.commit('GET_MOVIESITEM', res.data);
      });
    },
    setMoviesItem: (context, payload) => {
      context.commit('GET_MOVIESITEM', payload);
    },
  },
  mutations: {
    GET_MOVIESLIST: (state, payload) => {
      state.moviesList = payload;
      return state.moviesList;
    },
    GET_MOVIESITEM: (state, payload) => {
      state.movieItem = payload;
      return state.movieItem;
    },
  },
  getters: {
    moviesList(state) {
      return state.moviesList;
    },
    movieItem(state) {
      return state.movieItem;
    },
  },
  modules: {},
});
